<template>
  <b-sidebar
    id="edit-event-sidebar"
    :visible="isEditEventSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-event-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Atualizar 
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <!-- Title -->
          <validation-provider
            v-slot="{ errors }"
            name="title"
          >
            <b-form-group
              label="Título"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="form.title"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Banner -->
          <validation-provider
            v-slot="{ errors }"
            name="banner"
            vid="banner"
          >
            <b-form-group
              label="Banner"
              label-for="banner"
            >
              <b-form-file
                v-model="form.banner"
                placeholder="Escolher o banner"
                drop-placeholder="Drop file here..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Logo -->
          <validation-provider
            v-slot="{ errors }"
            name="logo"
            vid="logo"
          >
            <b-form-group
              label="Logo"
              label-for="logo"
            >
              <b-form-file
                v-model="form.logo"
                placeholder="Escolher o logo"
                drop-placeholder="Drop file here..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            v-slot="{ errors }"
            name="description"
            vid="description"
          >
            <b-form-group
              label="Descrição"
              label-for="description"
            >
              <b-form-textarea
                v-model="form.description"
                placeholder="Escreva aqui..."
                rows="6"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              @click="submit()"
              type="submit"
              :disabled="isBusy"
            >
              <b-spinner v-if="isBusy" />
              <template v-else>
                Salvar
              </template>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
          <div class="d-flex justify-content-end">
            <b-button variant="danger" @click="del(evento.id)" class="text-danger mt-1">
              <span class="align-middle ">Deletar</span>
              <feather-icon icon="InfoIcon" class="ml-50" />
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip, BFormTextarea
} from 'bootstrap-vue'
import Datepicker from 'vuejs-datepicker'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required, min_value as minValue, email, integer, required_if, min, confirmed
} from 'vee-validate/dist/rules'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
extend('confirmed', {
  ...confirmed,
  message: 'Senha de confirmação diferente da senha.',
})

extend('min', {
  ...min,
  message: 'Mínimo de caracteres são 6.',
})

extend('required_if', {
  ...required_if,
  message: 'Esse campo é obrigatório.',
})

extend('integer', {
  ...integer,
  message: 'Esse aceita apenas números.',
})

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

extend('email', {
  ...email,
  message: 'Digite um email válido.',
})

extend('min_length_deposit', {
  ...minValue,
  message: 'O valor minímo para depositar é R$ 1,00.',
})

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    Datepicker,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  props: {
    isEditEventSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      ptBR,
      evento: null,
      descriptionState: null,
      bannerState: null,
      form: {
        title: null,
        banner: null,
        logo: null,
        description: null,
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
  },
  created() {
  },
  beforeMount() {
    this.fetchEvent();
  },
  methods: {
    fetchEvent() {
      const apiUrl = process.env.VUE_APP_API + `/events/get-event/${this.$route.params.id}`;
      axios
        .get(apiUrl)
        .then((response) => {
          this.evento = response.data.response;
          this.form.title = this.evento.title;
          this.form.description = this.evento.description;

          console.log("Evento: " + this.form);
        })
        .catch((error) => {
          console.error("Error fetching eventos:", error);
        });
    },
    resetForm() {
      this.form = {
        title: null,
        banner: null,
        logo: null,
        description: null,
      }
    },
    del(id) {
      this.$swal({
        title: 'Deseja deletar este evento?',
        text: 'Você não será capaz de reverte essa ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3b65f0',
        cancelButtonColor: '#c1c1c1',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim, desejo deletar!',
        showLoaderOnConfirm: true,
        preConfirm: () => axios.post(`${process.env.VUE_APP_API}/events/delete-event/${id}`)
            .then(response => {
              console.log(response.data)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Sucesso ao deletar!',
                  icon: 'InfoIcon',
                  variant: 'success',
                }
              })
              this.$emit('close')
              // this.getListParticipantes()
            })
            .catch(error => {
              console.log(error)
              console.log(this.rowData.id)
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'InfoIcon',
                  title: error.message,
                  variant: 'danger',
                }
              })
            }),
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
            'Deletado!',
            'Evento deletado com sucesso.',
            'success'
          )

          this.$router.push('/administrador/eventos')
        }
      })
    },
    async submit() {
      try {
        // Disable the submit button to prevent multiple submissions
        this.isBusy = true;

        // Create a new FormData object to hold the form data, including files
        const formData = new FormData();
        formData.append('title', this.form.title);
        formData.append('description', this.form.description);

        if (this.form.banner) {
          formData.append('banner', this.form.banner);
        }
        
        if (this.form.logo) {
          formData.append('logo', this.form.logo);
        }

        // Make the HTTP request to the Laravel API endpoint using Axios
        const response = await axios.post(`${process.env.VUE_APP_API}/events/update-event/${this.$route.params.id}`, formData);

        // Check if the API call was successful
        if (response.status === 200) {
          // Show a success toast notification
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Operação efetuada com sucesso.",
              icon: "InfoIcon",
              variant: "success",
            },
          });

          // Optionally, reset the form after a successful submission
          // this.resetForm();
          this.$emit('close')
          // You can also perform other actions here, such as navigating to a different page
        } else {
          // Show an error toast notification
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "InfoIcon",
              title: "Erro",
              text: "ERROR: erro ao tentar gravar as informações",
              variant: "danger",
            },
          });
        }

      } catch (error) {
        // Handle errors if the API request fails
        console.error('Error submitting form:', error);
        
        // Optionally, show an error message to the user
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: "InfoIcon",
            title: "Erro ao tentar gravar as informações",
            variant: "danger",
          },
        });
      } finally {
        // Re-enable the submit button
        this.isBusy = false;
      }
    },

    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-congress-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
