<template>
  <div>
    <b-card>
      <router-link
        :to="`/administrador/eventos`"
        class="link-none"
      >
        <feather-icon icon="ArrowLeftIcon" size="20" class="" />
      </router-link>
    </b-card>
    <b-card-group class="grid-container m-1" deck>
      <edit-event 
          v-if="isEditEventSidebarActive" 
          :is-edit-event-sidebar-active.sync="isEditEventSidebarActive"
          :event="event" 
          @refresh="refresh()" 
          @close="isEditEventSidebarActive = false"
      />
      <!-- Loop para exibir os cards dinamicamente -->
      <router-link
        v-for="(card, index) in cardsData"
        :key="index"
        :to="`/administrador/eventos/conf/${card.link}/${$route.params.id}`"
        class="link-none"
      >
        <b-card class="text-center py-2">
          <feather-icon :icon="card.icon" size="70" class="mb-2" />
          <h2>{{ card.name }}</h2>
        </b-card>
      </router-link>
      <b-link
        class="link-none"
        @click="isEditEventSidebarActive = true"
      >
        <b-card class="text-center py-2">
          <feather-icon icon="EditIcon" size="70" class="mb-2" />
          <h2>Editar evento</h2>
        </b-card>
      </b-link>
    </b-card-group>
  </div>
</template>
<script>
// ================================================================= //
import axios from "axios";
import { BCard } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import EditEvent from './EditEvent.vue'
// ================================================================= //
export default {
  props: ['id'],
  components: {      
    EditEvent,
  },
  setup() {
      const isEditEventSidebarActive = ref(false)
      return {
        isEditEventSidebarActive,
      }
    },
  data() {
    return {
      event: null,
      cardsData: [
        { name: 'Participantes', icon: 'UsersIcon', link: 'participants' },
        { name: 'Aprovação', icon: 'CheckIcon', link: 'aprovation' },
        { name: 'Lives', icon: 'TvIcon', link: 'lives' },
        /*{ name: 'Crachá', icon: 'FileIcon', link: 'badge' },
        { name: 'Lives', icon: 'TvIcon', link: 'lives' },*/
      ]
    };
  },  
  created() {
    this.fetchEvent();
  },  
  methods: {    
    fetchEvent() {
      const apiUrl = process.env.VUE_APP_API + `/events/get-event/${this.$route.params.id}`;

      axios
        .get(apiUrl)
        .then((response) => {
          this.event = response.data;
          console.log(this.event)
        })
        .catch((error) => {
          console.error("Error fetching eventos:", error);
        });
      console.log(apiUrl)      
    },
  },
};
</script>
<style>
  .link-none {
    text-decoration: none !important;
    color: inherit;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 27px;
  }

  @media (max-width: 768px) {
    .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 20px;
  }
  }
</style>
